import React, { useEffect,  useMemo, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Button from '../../components/button'
import Preview from '../preview'
import { optionsColor } from './fieldOptions'

import globalStyle from '../../style.module.css'
import style from './style.module.css'

import Uppy from '@uppy/core'


const Step = () => {

    const { name, primary_color } = useSelector(state => state.form)

    const dispatch = useDispatch()

    const uppy = useMemo(() => {
        return new Uppy({
            restrictions: {
                maxNumberOfFiles: 5,
                allowedFileTypes: ['image/jpeg', 'image/png']
            }
        })
    }, [])


    

    // Update current screen
    useEffect(() => {
        dispatch({ type: 'SET_FIELD', field: 'current_step', payload: 0 })
    }, [dispatch])


    
    useEffect(() => {
        dispatch({ type: 'SET_FIELD', field: 'show_onboarding', payload: false })
    }, [dispatch])
   

    const updateUppy = useCallback(() => {
        return dispatch({ type: 'SET_FIELD', field: 'retailers', payload: uppy.getFiles()})
    }, [dispatch, uppy])

    useEffect(() => {
        uppy.on('files-added', updateUppy)
        uppy.on('file-removed', updateUppy)
    }, [uppy, updateUppy])


    return (
        <div className={globalStyle.container}>
            <div className={globalStyle.generatorCols}>
                <div className={`${globalStyle.generatorCol} ${globalStyle.generatorColAlt}`}>
                    <div className={`${globalStyle.preview}`}>
                        <Preview />
                    </div>
                </div>
                <div className={globalStyle.generatorCol} style={{backgroundColor: `${optionsColor.filter(e=>e.value === primary_color).map(e=>e.color)[0]}` }}>
                    <div className={globalStyle.form}>
                        <div >
                            <div className={globalStyle.content}>
                                <span className={style.title}>Yes! {name} is nu officieel een Groot Mens!</span>
                                <div style={{maxWidth: '410px', marginLeft: 'auto', marginRight: 'auto', marginTop: '30px',}}>
                                    <Button onClick={()=> window.location.href = process.env.REACT_APP_API_ENDPONT} full text="Vind jouw kaart terug de homepage" color={optionsColor.filter(e=>e.value === primary_color).map(e=>e.color)[0]} hideMobileIcon />
                                    <br/>
                                    <Button onClick={()=> window.location.href = '/'} full text="Nog een Groot Mens nomineren" color={optionsColor.filter(e=>e.value === primary_color).map(e=>e.color)[0]} hideMobileIcon />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

         

        </div>
    )
}

export default Step