export const optionsMediaType = [
    {
        key: `poster`,
        label: `Poster`,
        value: 'poster',
    },
    {
        key: `online-banner`,
        label: `Online banner`,
        value: 'online-banner',
    },
    {
        key: `social-media`,
        label: `Social Media`,
        value: 'social-media',
    },
    {
        key: `email-template`,
        label: `E-mail template`,
        value: 'email-template',
    },
]

export const optionsSize = [
    {
        key: `poster`,
        options: [
            {
                label: `A3 (420x297mm)`,
                value: 'A3',
            },
            {
                label: `A4 (297x210mm`,
                value: 'A4',
            }
        ]
    },
    {
        key: `online-banner`,
        options: [
            {
                label: `Square (300x250px)`,
                value: 'banner_square',
            },
            {
                label: `Leaderboard (728x90 px)`,
                value: 'banner_leaderboard',
            }
        ]
    },
    {
        key: `social-media`,
        options: [
            {
                label: `Instagram & Facebook feed (1080x1080 px)`,
                value: 'instagram-facebook'
            }
        ]
    },
    {
        key: `email-template`,
        options: [
            {
                label: `E-mail template`,
                value: 'email'
            }
        ]
    },
]

export const optionsColor = [
    {
        key: `orange`,
        label: `orange`,
        value: 'orange',
        color: '#F58349'
    },
    {
        key: `green`,
        label: `green`,
        value: 'green',
        color: '#91C662'
    },
    {
        key: `yellow`,
        label: `yellow`,
        value: 'yellow',
        color: '#F7B71A'
    },
    {
        key: `red`,
        label: `red`,
        value: 'red',
        color: '#E8564F'
    },
    {
        key: `pink`,
        label: `pink`,
        value: 'pink',
        color: '#EC93CE'
    },
    {
        key: `blue`,
        label: `blue`,
        value: 'blue',
        color: '#7AC3E7'
    },
   
]