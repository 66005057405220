import React from 'react'
import style from './style.module.css'
const ContentInput = ({ handleChange, handleFontScale, value, focussed, onFocus, onBlur, currentFontSize, minFontSize, maxFontSize }) => {

    return (
        <div className={style.container}>
            <textarea 
                placeholder="Reden (maximaal 125 tekens)" 
                defaultValue={value} 
                onChange={handleChange} 
                maxLength={125}
            />

        </div>
    )
}

export default ContentInput