import React from 'react'
import globalStyle from '../../style.module.css'

const Button = ({color = '#F58349', children, disabled = false, onClick, text="", full, hideMobileIcon = null}) => {
    return (
        <button className={`${globalStyle.button} ${hideMobileIcon ? globalStyle.hideBtnIcon : ''} ${disabled ? globalStyle.disableBtn : ''}`} onClick={onClick} style={{color: color, width: full ? '100%' : ''}}>
            <span className={globalStyle.text}>{text}</span>
            <span className={`${globalStyle.icon} ${globalStyle.down}`}>
                <svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7.5 0.5L6.17812 1.82187L11.4094 7.0625L-3.68815e-07 7.0625L-2.86856e-07 8.9375L11.4094 8.9375L6.16875 14.1687L7.5 15.5L15 8L7.5 0.5Z" fill={color}/>
                </svg>
                <svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7.5 0.5L6.17812 1.82187L11.4094 7.0625L-3.68815e-07 7.0625L-2.86856e-07 8.9375L11.4094 8.9375L6.16875 14.1687L7.5 15.5L15 8L7.5 0.5Z" fill={color}/>
                </svg>
            </span>
        </button>
    )    
}

export default Button