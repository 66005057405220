import React, { useEffect, useState, useMemo, useCallback, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Checkbox from '../../components/checkbox'
import Button from '../../components/button'
import ButtonAlt from '../../components/button/buttonAlt'
import RadioButtonGroup from '../../components/radioButtonGroup'
import Preview from '../preview'
import NameInput from '../../components/nameInput'
import ContentInput from '../../components/contentInput'
import Pagination from '../pagination'
import { optionsColor } from './fieldOptions'
import { useNavigate } from 'react-router-dom'
import routeConfig from '../../routes'

import globalStyle from '../../style.module.css'

import Uppy from '@uppy/core'
import { processDownload } from '../../utilities'

const Step = () => {

    const { media_size, name,  content, primary_color } = useSelector(state => state.form)

    const dispatch = useDispatch()
    const handleChange = (field, payload) => {
        dispatch({ type: 'SET_FIELD', field, payload })
    }

    const navigate = useNavigate();
    


    const uppy = useMemo(() => {
        return new Uppy({
            restrictions: {
                maxNumberOfFiles: 5,
                allowedFileTypes: ['image/jpeg', 'image/png']
            }
        })
    }, [])


    // Number of retailers
    const [current, setCurrent] = useState(false)
    const [popupOpen, setPopupOpen] = useState(false)
    const [nameError, setNameError] = useState(false)
    const [contentError, setContentError] = useState(false)
    const mediaRender = useRef(null)
    


    const handleNameChange = (event) => {
        handleChange('name', event.currentTarget.value)
    }
    const handleContentChange = (event) => {
        handleChange('content', event.currentTarget.value)
    }

    // Update current screen
    useEffect(() => {
        dispatch({ type: 'SET_FIELD', field: 'current_step', payload: 0 })
    }, [dispatch])



    
    useEffect(() => {
        dispatch({ type: 'SET_FIELD', field: 'show_onboarding', payload: false })
    }, [dispatch])
   

    const updateUppy = useCallback(() => {
        return dispatch({ type: 'SET_FIELD', field: 'retailers', payload: uppy.getFiles()})
    }, [dispatch, uppy])

    useEffect(() => {
        uppy.on('files-added', updateUppy)
        uppy.on('file-removed', updateUppy)
    }, [uppy, updateUppy])

    const handleGdpr = () => {
        setCurrent(!current)
    }
    const closePopup = () => {
        setPopupOpen(false);
    }
    const openPopup = () => {
        if(name.length >= 2 && content.length >= 15) {
            setPopupOpen(true);
            dispatch({ type: 'SET_FIELD', field: 'rotated', payload: false })
        } else {
            setNameError(name.length >= 2 ? false : true)
            setContentError(content.length >= 15 ? false : true)
        }
    }
    const handleGenerate = () => {
        processDownload(document.getElementById('posterFront'), media_size, content, current, primary_color).then((data)=> {
            dispatch({ type: 'SET_FIELD', field: 'download', payload: data.image})
            dispatch({ type: 'SET_FIELD', field: 'shareUrl', payload: data.data.url})
            setTimeout(()=> {
                navigate(routeConfig[2].path)
            }, 100)
        })
    }

    return (
        <div className={globalStyle.container}>
            <div className={globalStyle.generatorCols}>
                <div className={`${globalStyle.generatorCol} ${globalStyle.generatorColAlt}`}>
                    <div ref={mediaRender} className={`${globalStyle.preview}`}>
                        <Preview />
                    </div>
                </div>
                <div className={globalStyle.generatorCol} style={{backgroundColor: `${optionsColor.filter(e=>e.value === primary_color).map(e=>e.color)[0]}` }}>
                    <div className={globalStyle.form}>
                        <Pagination />
                        <div className={globalStyle.content}>
                            <p style={{marginTop: '0px'}}>Vul de gegevens hieronder in en bekijk het resultaat links.</p>
                        </div>
                        <div className={globalStyle.formRow}>
                            <span className={globalStyle.subtitle} style={{display: 'flex', justifyContent: 'space-between'}}><span style={{flex: 1, paddingRight: '10px'}}>Wie is jouw Grote Mens?&nbsp;*</span> <span style={{width: 'width: 90px;', textAlign: 'right'}}>{name.length} / 16    </span></span>
                            {
                                nameError && (
                                    <span className={globalStyle.error}>Vul minimaal 2 tekens in.</span>
                                )
                            }
                            <NameInput
                                handleChange={handleNameChange}
                                value={name}
                            />
                        </div>
                        <div className={globalStyle.formRow}>
                            <span className={globalStyle.subtitle} style={{display: 'flex', justifyContent: 'space-between'}}><span style={{flex: 1, paddingRight: '10px'}}>Wat maakt hem of haar Groot?&nbsp;*</span> <span style={{width: 'width: 90px;', textAlign: 'right'}}>{content.length} / 125    </span></span>
                            {
                                contentError && (
                                    <span className={globalStyle.error}>Vul minimaal 15 tekens in.</span>
                                )
                            }
                            <ContentInput
                                handleChange={handleContentChange}
                                value={content}
                            />
                        </div>
                        <div className={globalStyle.formRow}>
                            <span className={globalStyle.subtitle}>Kies een kleur</span>
                            <RadioButtonGroup 
                                identifier="primary_color" // Name in reducer 
                                currentValue={primary_color}
                                options={optionsColor} 
                                handleChange={handleChange}
                            />
                        </div>
                        <div className={globalStyle.formRow}>
                            <Checkbox
                                identifier="gdpr"
                                label="Ik geef toestemming om mijn kaart te tonen tussen alle andere Grote Mensen op deze website op het moment dat ik op ‘Kaart aanmaken’ klik."
                                checked={current}
                                onChange={handleGdpr}
                                color={optionsColor.filter(e=>e.value === primary_color).map(e=>e.color)[0]}
                            />
                        </div>
                        
                        <div className={globalStyle.section}>
                            <Button disabled={name.length && content.length ? false : true} onClick={openPopup} text="Kaart aanmaken" color={optionsColor.filter(e=>e.value === primary_color).map(e=>e.color)[0]} />
                        </div>
                            
                    </div>
                </div>
            </div>

            <div className={`${globalStyle.modal} ${popupOpen ? globalStyle.modalIsOpen : ''}`}>
                <div className={globalStyle.modalInner} style={{backgroundColor: optionsColor.filter(e=>e.value === primary_color).map(e=>e.color)[0]}}>
                    <p style={{marginBottom: 20,}}>Ho, stop! Weet je zeker dat je de kaart aan wilt maken? Als je nu verder gaat wordt jouw Grote Mens gedeeld op de website en kun je er niets meer in aanpassen.</p>
                    <div className={globalStyle.modalButtons}>
                        <Button onClick={handleGenerate} text="Ik weet het zeker" color={optionsColor.filter(e=>e.value === primary_color).map(e=>e.color)[0]} />&nbsp;&nbsp;&nbsp;&nbsp;
                        <ButtonAlt onClick={closePopup} text="Oei, nog iets aanpassen" />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Step