import React from 'react'
import { useSelector } from 'react-redux'
import { useNavigate, useLocation } from 'react-router-dom'

import style from './style.module.css'
import globalStyle from '../../style.module.css'
import routeConfig from '../../routes'
import { optionsColor } from '../step1/fieldOptions'

const options = [
    {
        path: "/inleiding",
        name: "Inleiding"
    }, {
        path:"/kaart-aanmaken", 
        name: "Kaart aanmaken"
    }, {
        path: "/kaart-delen", 
        name: "Kaart delen"
    }
]


const PaginationDesktop = () => {
    const location = useLocation();
    const { primary_color } = useSelector(state => state.form)
    const navigate = useNavigate();

    const handleNavigation = (index) => {
        navigate(routeConfig[index].path)
    }

    return (
        <div className={` ${style.pagination}`}>
            <ul className={style.list}>

                {
                    options.map((item, index) => {

                        // console.log(location.pathname == routeConfig.filter(e=>e.path == location.pathname)[0].path);
                        return (
                            <li key={`pagination-item-${index}`} className={`${style.listItem} ${location.pathname === item.path ? style.listItemActive : ''}`}>
                                {/* {
                                    !isHistory ? (
                                        <svg width="30" height="31" viewBox="0 0 30 31" xmlns="http://www.w3.org/2000/svg"><path d="m15.01 3.696 8.493 3.465 3.784 8.8-3.674 8.893-8.604 3.456-8.651-3.39-3.65-8.959L6.467 7.1l8.541-3.403z" stroke="#FFF" strokeWidth="5" fill="none" fillRule="evenodd"/></svg>
                                    ) : (
                                        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="m15.01 2.696 8.493 3.465 3.784 8.8-3.674 8.893-8.604 3.456-8.651-3.39-3.65-8.959L6.467 6.1l8.541-3.403Z" fill="#fff" stroke="#fff" strokeWidth="5"/></svg>
                                    )
                                } */}
                                <button className={style.listItemButton} onClick={() => { handleNavigation(index) }}>
                                    <span className={style.indicator} style={{color: optionsColor.filter(e=>e.value === primary_color).map(e=>e.color)[0] }}>{index + 1}</span>
                                    {item.name}
                                </button>
                            </li>
                        )
                    })
                }
                
            </ul>
        </div>
    )
}

export default PaginationDesktop


