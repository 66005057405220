import React, { useEffect,  useMemo, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Button from '../../components/button'
import Preview from '../preview'
import Pagination from '../pagination'
import { optionsColor } from './fieldOptions'
import { useNavigate } from 'react-router-dom'

import globalStyle from '../../style.module.css'

import Uppy from '@uppy/core'

import { downloadFile } from '../../utilities'

const Step = () => {
    const navigate = useNavigate();

    const { name, content,  primary_color, download, shareUrl } = useSelector(state => state.form)


    const dispatch = useDispatch()



    const uppy = useMemo(() => {
        return new Uppy({
            restrictions: {
                maxNumberOfFiles: 5,
                allowedFileTypes: ['image/jpeg', 'image/png']
            }
        })
    }, [])


    // Number of retailers

    // Update current screen
    useEffect(() => {
        dispatch({ type: 'SET_FIELD', field: 'current_step', payload: 0 })
    }, [dispatch])


    
    useEffect(() => {
        dispatch({ type: 'SET_FIELD', field: 'show_onboarding', payload: false })
    }, [dispatch])
   

    const updateUppy = useCallback(() => {
        return dispatch({ type: 'SET_FIELD', field: 'retailers', payload: uppy.getFiles()})
    }, [dispatch, uppy])

    useEffect(() => {
        uppy.on('files-added', updateUppy)
        uppy.on('file-removed', updateUppy)
    }, [uppy, updateUppy])

 
 
    const gotoDone = () => {
        navigate('/klaar')
    }
    const handleDownload = () => {
        downloadFile(download, `${name}-is-een-groot-mens@2x`)
    }


    const handleShare = (link, shareTitle) => {
        let title = shareTitle || 'Deel'
        let w = 460
        let h = 600
        let left = (window.innerWidth.width/2)-(w/2)
        let top = (window.innerWidth.height/2)-(h/2)
        console.log(222)
        window.open(link, title, 'toolbar=no, directories=no, status=no, menubar=no, resizable=1, copyhistory=no, width='+w+', height='+h+', top='+top+', left='+left);
    }


    return (
        <div className={globalStyle.container}>
            <div className={globalStyle.generatorCols}>
                <div className={`${globalStyle.generatorCol} ${globalStyle.generatorColAlt}`}>
                    <div className={`${globalStyle.preview}`}>
                        <Preview />
                    </div>
                </div>
                <div className={globalStyle.generatorCol} style={{backgroundColor: `${optionsColor.filter(e=>e.value === primary_color).map(e=>e.color)[0]}` }}>
                    <div className={globalStyle.form}>
                        <div>
                            <Pagination />
                            <div className={globalStyle.content}>
                                <p>Bijna klaar! Deel de voorkant met je netwerk, of download de kaart als afbeelding.</p>
                                <p>Gebruik voor het delen op social media de hashtags #meergrotemensen en #maakgezondgewoon.</p>
                            </div>
                            <div>
                                <button onClick={()=> handleShare(`https://www.linkedin.com/sharing/share-offsite/?url=${shareUrl}`)}  className={`${globalStyle.button} ${globalStyle.buttonPrimary} ${globalStyle.buttonShare}`} target="_blank">
                                    <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M4.12777 1.97466C4.12777 3.05104 3.30819 3.92214 2.02652 3.92214C0.794782 3.92214 -0.0247915 3.05104 0.000572519 1.97466C-0.0247915 0.84597 0.794763 0.000244141 2.05107 0.000244141C3.30818 0.000244141 4.1032 0.84597 4.12777 1.97466ZM0.103618 17.9983V5.46061H4.00015V17.9975H0.103618V17.9983Z" fill="white"/>
                                        <path fillRule="evenodd" clipRule="evenodd" d="M7.12453 9.46186C7.12453 7.89802 7.073 6.56482 7.02148 5.46229H10.406L10.5859 7.1799H10.6628C11.1756 6.3849 12.4573 5.18091 14.534 5.18091C17.0973 5.18091 19.0202 6.87316 19.0202 10.5636V18H15.1237V11.0519C15.1237 9.4357 14.5601 8.33396 13.1501 8.33396C12.0729 8.33396 11.4324 9.07744 11.1764 9.79476C11.0734 10.0516 11.0227 10.4098 11.0227 10.7697V18H7.1261V9.46186H7.12453Z" fill="white"/>
                                    </svg>
                                    <span>Linkedin</span>
                                </button>
                                <button onClick={()=> handleShare(`http://twitter.com/share?text=${name} is een Groot Mens omdat ${content}  @JOGGNL &url=${shareUrl}&hashtags=maakgezondgewoon,meergrotemensen`)} target="_blank"  className={`${globalStyle.button} ${globalStyle.buttonPrimary} ${globalStyle.buttonShare}`} >
                                    <svg width="23" height="18" viewBox="0 0 23 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M22.1685 2.13059C21.3542 2.49233 20.4779 2.73595 19.5588 2.84595C20.4971 2.2834 21.2176 1.39307 21.5565 0.33221C20.6794 0.853414 19.7057 1.23066 18.6714 1.43516C17.8424 0.552211 16.6604 0 15.3544 0C12.8451 0 10.8105 2.03461 10.8105 4.54392C10.8105 4.89976 10.8511 5.24674 10.9286 5.57969C7.15176 5.3907 3.8038 3.58125 1.56247 0.832006C1.1712 1.50308 0.947511 2.28341 0.947511 3.11616C0.947511 4.69232 1.74851 6.08318 2.96884 6.89821C2.22321 6.87459 1.52334 6.67009 0.909858 6.32975V6.3866C0.909858 8.58879 2.47716 10.4248 4.5546 10.8434C4.17366 10.9468 3.77205 11.0029 3.35789 11.0029C3.06481 11.0029 2.77985 10.9741 2.50226 10.9209C3.08031 12.7259 4.75909 14.04 6.7472 14.0769C5.19244 15.2951 3.23313 16.0222 1.10402 16.0222C0.73637 16.0222 0.374629 16.0008 0.0195312 15.958C2.03052 17.247 4.41876 18 6.98491 18C15.3426 18 19.9131 11.076 19.9131 5.07177C19.9131 4.87466 19.9094 4.67829 19.8999 4.48413C20.7887 3.84185 21.5594 3.04159 22.1685 2.13059Z" fill="white"/>
                                    </svg>
                                    <span>Twitter</span>
                                </button>
                                <button onClick={()=> handleShare(`https://facebook.com/sharer/sharer.php?u=${shareUrl}`)} target="_blank"  className={`${globalStyle.button} ${globalStyle.buttonPrimary} ${globalStyle.buttonShare}`} >
                                    <svg width="11" height="20" viewBox="0 0 11 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M9.54624 3.598C8.97958 3.48467 8.21425 3.4 7.73292 3.4C6.42959 3.4 6.34492 3.96667 6.34492 4.87333V6.48734H9.60291L9.31892 9.83067H6.34492V20H2.2656V9.83067H0.168945V6.48734H2.2656V4.41933C2.2656 1.58667 3.59694 0 6.93959 0C8.10092 0 8.95092 0.170001 10.0556 0.396667L9.54624 3.598Z" fill="white"/>
                                    </svg>
                                    <span>Facebook</span>
                                </button>
                                <button  onClick={handleDownload} className={`${globalStyle.button} ${globalStyle.buttonPrimary} ${globalStyle.buttonShare}`} >
                                    <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M21.0006 13.0382V17.9171C21.0003 18.0084 20.9892 18.0993 20.9676 18.1877C20.9221 18.4236 20.8007 18.6358 20.6237 18.7888C20.4466 18.9417 20.2249 19.026 19.9956 19.0275H0.999071C0.754686 19.0266 0.519171 18.9313 0.337459 18.7598C0.155747 18.5882 0.0405592 18.3525 0.0138708 18.0975C0.00265369 18.0356 -0.00178457 17.9726 0.000645196 17.9096V13.0308C0.0013929 12.7549 0.106129 12.4906 0.291968 12.2955C0.477807 12.1005 0.729643 11.9905 0.992458 11.9898C1.25502 11.9906 1.5066 12.1004 1.6924 12.2951C1.87814 12.4904 1.98273 12.7548 1.98333 13.0308V16.938H19.0255V13.0308C19.0263 12.7549 19.131 12.4906 19.3168 12.2955C19.5027 12.1005 19.7545 11.9905 20.0173 11.9898C20.2802 11.9904 20.5322 12.1002 20.7182 12.2951C20.8978 12.4969 20.9986 12.7621 21.0006 13.0382ZM9.6609 13.2672L9.68736 13.295C9.86011 13.4787 10.0853 13.5981 10.3287 13.6351C10.3486 13.6351 10.3684 13.642 10.3882 13.642L10.5006 13.6489C10.5404 13.6501 10.5802 13.6478 10.6197 13.642C10.6395 13.642 10.6593 13.6351 10.6792 13.6351C10.9221 13.5966 11.1469 13.4775 11.3205 13.295C11.3272 13.2881 11.3404 13.2811 11.347 13.2672L16.2201 8.1524C16.4343 7.92354 16.5559 7.61661 16.5594 7.29582C16.5629 6.97504 16.4481 6.66525 16.239 6.43127C16.0208 6.20447 15.7262 6.07724 15.4191 6.07724C15.112 6.07724 14.8174 6.20447 14.5992 6.43127L11.671 9.50473L11.6698 2.18713C11.671 2.02734 11.6418 1.86891 11.5839 1.72113C11.526 1.57335 11.4405 1.4392 11.3325 1.32656C11.1134 1.10128 10.8194 0.974342 10.5126 0.972619C10.2062 0.974182 9.91276 1.10264 9.69608 1.33007C9.4794 1.5575 9.35701 1.8655 9.35552 2.18713L9.35675 9.50572L6.42854 6.43226C6.21037 6.20546 5.91571 6.07823 5.60864 6.07823C5.30157 6.07823 5.00692 6.20546 4.78874 6.43226C4.68136 6.54536 4.59629 6.6796 4.53842 6.82727C4.48056 6.97494 4.45103 7.13315 4.45152 7.29283C4.45103 7.4525 4.48056 7.61071 4.53842 7.75838C4.59629 7.90606 4.68136 8.04029 4.78874 8.15339L9.6609 13.2672Z" fill="white"/>
                                    </svg>
                                    <span>Kaart downloaden</span>
                                </button>
                            </div>
                        </div>
                        
                        <div className={globalStyle.section}>
                            <Button onClick={gotoDone} text="Ik ben klaar met delen" color={optionsColor.filter(e=>e.value === primary_color).map(e=>e.color)[0]} />
                        </div>
                    </div>
                </div>
            </div>

      

        </div>
    )
}

export default Step