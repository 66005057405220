import React from 'react'
import style from './style.module.css'

const RadioGroup = ({identifier, currentValue, options, handleChange }) => {

    const onChange = (event) => {
        
        
        let value = event.target.value

        // Force to boolean if its a string containing a boolean
        if(event.target.value === 'true' || event.target.value === 'false') {
            value = (event.target.value === 'true')
        }

        handleChange(identifier, value)
    }

    return (
        <div className={style.options}>
            {
                options.map(option => {
                    
                    const key = `${identifier}-${option.key}`
                    const isChecked = currentValue === option.value
          
                    return (
                        <div key={key} className={`${isChecked ? style.active : ''} ${style.option} ${option?.color ? style.coloredOption : ''}`}>
                            <input 
                                id={key} 
                                name={identifier} 
                                type="radio" 
                                onChange={onChange} 
                                value={option.value} 
                                checked={isChecked}
                            />
                            <label 
                                htmlFor={key}
                                className={style.box}
                                style={option?.color ? {'--theme': option.color } : {}}
                            ></label>
                        </div>
                    )

                })
            }
        </div>
    )
}

export default RadioGroup