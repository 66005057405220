import Step1 from './containers/step1'
import Step2 from './containers/step2'
import Step3 from './containers/step3'
import Step4 from './containers/step4'

const routes = [
    {
        path: "/inleiding",
        element: <Step1 />,
        name: "Inleiding"
    }, {
        path:"/kaart-aanmaken", 
        element: <Step2 />,
        name: "Kaart aanmaken"
    }, {
        path: "/kaart-delen", 
        element:<Step3 />,
        name: "Kaart delen"
    }, {
        path: "/klaar", 
        element:<Step4 />,
        name: "Klaar"
    }
]

export default routes