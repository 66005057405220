const initialState = {

    // Global
    current_step: 1,
    is_editting: false,
    navigation_visible: true, // Hide on textInput focus
    font_scale: 1,
    show_onboarding: true,
    rotated: false,

    // Step 1
    use_name: 'use_club_name',
    name: '',
    content: '',
    logo: null,
    message: 'Shop and donate to',
    retailer_visibility_type: 'no',
    retailers: [],
    use_logos: false,
    download: '',
    onSite: false,
    shareUrl: '',

    // Step 2
    primary_color: 'orange',
    illustration: 'illustration-1.png',

    // Step 3
    media_size: 'banner_square'

};
const list = (state = initialState, action) => {
    

    switch (action.type) {


        case 'SET_FIELD':

            let obj = {
                ...state,
                [action.field]: action.payload,
            }

            if(action.field !== 'current_step') {
                obj.is_editting = true
            }

            return obj

        case 'SET_RETAILER_VISIBILITY_CHANGE':
            return {
                ...state,
                [action.field]: action.payload,
                retailers: []
            }

        case 'RESET': 
            return {
                ...state,
                current_step: 0,
                use_name: 'use_club_name',
                message: 'Shop and donate to',
                retailer_visibility_type: 'no',
                retailers: [],
                logo: null,
                use_logos: false,
                primary_color: 'dark-green',
                illustration: 'illustration-1.png',
                media_type: 'poster',
                media_size: 'A3',
                is_editting: false,
            }

        default:
            return state;
    }
  }

  export default list