import React from 'react'
import globalStyle from '../../style.module.css'

const ButtonAlt = ({color = '#F58349', children, disabled = false, onClick, text}) => {
    return (
        <button className={globalStyle.buttonAlt} onClick={onClick} >
            {text}
        </button>
    )    
}

export default ButtonAlt