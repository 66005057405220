import React, {useEffect} from 'react'
import { useSelector, useDispatch } from 'react-redux'
import './size.css'
import style from './style.module.css'
import BannerSquare from '../../templates/banner_square'
import fitty from 'fitty'

const Preview = ({ selectorHidden = false }) => {

    const { media_size, rotated } = useSelector(state => state.form)
    const dispatch = useDispatch()
    
    const handleRotate = () => {
        dispatch({ type: 'SET_FIELD', field: 'rotated', payload: !rotated })
    }

    useEffect(() => {
        setTimeout(()=> {
            [...document.querySelectorAll('.size-square')].forEach(item => { 
                fitty(item.querySelector('.name'), {maxSize: item.clientHeight/1.2});
            })
        })
    })
    setTimeout(()=> {
        [...document.querySelectorAll('.size-square')].forEach(item => { 
            fitty(item.querySelector('.name'), {maxSize: item.clientHeight/1.2});
        })
    }, 100);

    return (
        <>
            <div className={`${style.preview} preview-container`}>
                {
                    {
                        'banner_square': <BannerSquare rotated={rotated} />,
                    }[media_size]
                }
            </div>
            <div className={`${style.preview} preview-render`} id="preview-render">
                <div className={`${style.previewRenderInner} preview-render-inner`}>
                    {
                        {
                            'banner_square': <BannerSquare rotated={rotated} render={true} />,
                        }[media_size]
                    }
                </div>
            </div>
            <div className={`${style.preview} preview-render-small`} id="preview-render-small">
                {
                    {
                        'banner_square': <BannerSquare rotated={rotated} render={true} size={'760px'} />,
                    }[media_size]
                }
            </div>
            <button className={style.flipTrigger} onClick={() => { handleRotate() }}>
                <span>
                    {rotated ? 'Bekijk het resultaat op de voorkant' : 'Bekijk het verhaal op de achterkant'}
                </span>
                <svg className={`${rotated ? style.svgRotated : ''}`} width="29" height="30" viewBox="0 0 29 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12.9676 10.7579L14.3818 9.34368L12.3242 7.286C14.9899 6.57182 17.9527 7.25771 20.0387 9.34368C22.6691 11.9741 23.0722 15.9976 21.2691 19.0593L22.7257 20.516C25.2925 16.6339 24.8753 11.3519 21.4529 7.92947C19.1407 5.61723 15.9658 4.67678 12.9606 5.10811L14.3818 3.68683L12.9676 2.27261L8.72498 6.51525L12.9676 10.7579Z" fill="white"/>
                    <path d="M15.7962 19.2415L14.3819 20.6557L16.4396 22.7134C13.7738 23.4275 10.8111 22.7417 8.72509 20.6557C6.09465 18.0252 5.6916 14.0018 7.49473 10.94L6.03809 9.4834C3.47129 13.3654 3.88848 18.6475 7.31088 22.0699C9.62312 24.3821 12.798 25.3226 15.8032 24.8913L14.3819 26.3125L15.7962 27.7268L20.0388 23.4841L15.7962 19.2415Z" fill="white"/>
                </svg>
            </button>
        </>
    )
}

export default Preview


