import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Button from '../../components/button'
import Preview from '../preview'
import Pagination from '../pagination'
import { optionsColor } from './fieldOptions'
import globalStyle from '../../style.module.css'
import { useNavigate } from 'react-router-dom'
import routeConfig from '../../routes'

const Step = () => {
    const navigate = useNavigate();

    const {primary_color } = useSelector(state => state.form)

    const dispatch = useDispatch()


    // Update current screen
    useEffect(() => {
        dispatch({ type: 'SET_FIELD', field: 'current_step', payload: 0 })
    }, [dispatch])

    
    useEffect(() => {
        dispatch({ type: 'SET_FIELD', field: 'show_onboarding', payload: false })
    }, [dispatch])


    return (
        <div className={globalStyle.container}>
            <div className={globalStyle.generatorCols}>
                <div className={`${globalStyle.generatorCol} ${globalStyle.generatorColAlt}`}>
                    <div className={`${globalStyle.preview}`}>
                        <Preview />
                    </div>
                </div>
                <div className={globalStyle.generatorCol} style={{backgroundColor: `${optionsColor.filter(e=>e.value === primary_color).map(e=>e.color)[0]}` }}>
                    <div className={globalStyle.form}>
                        <Pagination />
                        <div className={globalStyle.content}>
                            <h2 className={globalStyle.title}>Ken jij iemand die zich inzet voor een gezonde omgeving?</h2>
                            <p>Benoem hem of haar tot officieel Groot Mens en deel het verhaal op sociale media en deze website. Gebruik de #meergrotemensen en #maakgezondgewoon.</p>
                            <p>Waarom zou je dit doen? Lees <a style={{color: '#fff'}} href="https://meergrotemensen.nl/" target="_blank">hier</a> wat een volwassene écht groot maakt.</p>
                        </div>
                        <div className={globalStyle.section}>
                            <Button onClick={()=>navigate(routeConfig[1].path)} text="Beginnen met mijn kaart" color={optionsColor.filter(e=>e.value === primary_color).map(e=>e.color)[0]} />
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default Step