import React from 'react'
import PaginationDesktop from './desktop'

const Pagination = () => {


    return (
        <PaginationDesktop />
    )
}

export default Pagination


