export const optionsName = [
    {
        key: `use_club_name`,
        label: `Yes, use my club name`,
        value: 'use_club_name',
    }, {
        key: `use_name_and_logo`,
        label: `Yes, use my club name and logo`,
        value: 'use_club_name_and_logo',
    }, {
        key: `dont_use_name`,
        label: `No, use a generic text`,
        value: 'default',
    }
]

export const optionsMessage = [
    {
        key: `donate_to`,
        label: `Shop and donate to`,
        value: 'Shop and donate to',
    }, {
        key: `support`,
        label: `Support`,
        value: 'Support',
    }, {
        key: `save_for`,
        label: `Shop and save up for`,
        value: 'Shop and save up for',
    }
]

export const optionsRetailer = [
    {
        key: `shop_names`,
        label: `Use shop names`,
        value: 'shop_names',
    }, {
        key: `shop_logos`,
        label: `Use shop logo’s`,
        value: 'shop_logos',
    }, {
        key: `no`,
        label: `Don’t show any specific shops`,
        value: 'no',
    }
]

export const optionsColor = [
    {
        key: `orange`,
        label: `orange`,
        value: 'orange',
        color: '#F58349'
    },
    {
        key: `green`,
        label: `green`,
        value: 'green',
        color: '#91C662'
    },
    {
        key: `yellow`,
        label: `yellow`,
        value: 'yellow',
        color: '#F7B71A'
    },
    {
        key: `red`,
        label: `red`,
        value: 'red',
        color: '#E8564F'
    },
    {
        key: `pink`,
        label: `pink`,
        value: 'pink',
        color: '#EC93CE'
    },
    {
        key: `blue`,
        label: `blue`,
        value: 'blue',
        color: '#7AC3E7'
    },
   
]